import * as Yup from 'yup'

export const RegisterFormSchema = Yup.object().shape({
  company_name: Yup.string()
    .min(4, '1b2ca0f35')
    .max(30, '59206c5d2')
    .required('88228be84'),
  company_address: Yup.string()
    .min(7, '75166399b')
    .max(300, '825b440a7')
    .required('88228be84'),
  country_dial_code: Yup.string()
    .required('88228be84'),
  phone_number: Yup.string()
    .min(4, '34394b13b')
    .max(14, 'fc3a7943f')
    .required('88228be84')
})
