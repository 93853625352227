import styled from 'styled-components'
import { cerulean, darkBlueGrey, mykonosBlue } from 'components/baseColor'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Wrapper = styled.div`
  padding: 0 0 2rem;
  width: calc(100vw - 15rem);
  max-width: 1600px;
  margin: auto;
  font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;

  ${IS_FRAME_MOBILE} {
    width: unset;
    margin: 0 1rem;

    > svg {
      width: 5rem;
      height: 3rem;
      margin-bottom: 1rem;
    }
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${darkBlueGrey};
  letter-spacing: 0.25px;
  align-items: center;
  font-size: 1rem;

   a {
    text-decoration: none;
    color: ${cerulean};
    font-weight: bold;
  }

   svg {
    width: 5rem;
    height: 5rem;
  }

  > div:nth-child(2) {
    margin-left: auto;
    display: block;
  }

  ${IS_FRAME_MOBILE} {
    display: block;
    font-size: 0.875rem;
    letter-spacing: 0.25px;
    margin-top: 1rem;
  }

  ${IS_FRAME_TABLET} {
    display: block;
    font-size: 0.875rem;
    letter-spacing: 0.25px;
    margin-top: 1rem;
  }
`

export const Back = styled.div`
  display: flex;
  color: ${cerulean};
  font-weight: bold;
  font-size: 1rem;  
  align-items: center;
  cursor: pointer;

  > svg {
    width: 2rem;
    height: 2rem;

    g > path:first-child {
      fill: ${cerulean};
    }
  }

  ${IS_FRAME_MOBILE} {
    > svg {
      margin-left: -0.5rem;
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
  }

  ${IS_FRAME_TABLET} {
    > svg {
      margin-left: -0.5rem;
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr max-content;
  padding: 2rem 2rem 0;
  grid-column-gap: 4rem;

  ${IS_FRAME_MOBILE} {
    display: block;
    padding: 2rem 0 0;
  }
  ${IS_FRAME_TABLET} {
    display: block;
    padding: 2rem 0 0;
  }
` 

export const Card = styled.div`
  display: block;
  margin: auto;
  border: solid 1px #dee2ee;
  border-radius: 0.5rem;
  background-color: white;
  padding: 0 2rem 2rem;
  color: ${darkBlueGrey};
  font-family: 'Open Sans',system-ui,-apple-system;

  > a {
    color: ${mykonosBlue};
    font-weight: 600;
    text-decoration: unset;
    letter-spacing: 0.5px;
    font-size: 1rem;
    display: flex;
    align-items: center;

    > svg {
      padding-right: 0.75rem;
    }

    > svg path {
      fill: ${mykonosBlue};
    }

    > span svg path {
      fill: ${mykonosBlue};
    }
  }

  > h2 {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    font-size: 1.5rem;
    margin: 2rem 0 1rem;
  }

  > h3 {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    font-size: 1.4rem;
    margin: 2rem 0 1rem;
  }

  > p {
    line-height: normal;
    letter-spacing: 0.1px;
    line-height: 1.25; 
    font-size: 0.95rem;
    margin: 0.5rem 0 2rem;
  }

  ${IS_FRAME_MOBILE} {
    padding: 1rem;
    margin: auto;
    width: unset;

    > h2 {
      letter-spacing: 0.25px;
      font-size: 1.25rem;
      margin: 1rem 0 1.5rem;
    }

    > h3 {
      letter-spacing: 0.25px;
      font-size: 1.25rem;
      margin: 0.5rem 0 1.5rem;
      font-size: 1.25rem;
    }

    > p {
      line-height: normal;
      line-height: 1.57;
      letter-spacing: 0.25px;
      font-size: 0.875rem;
      margin: 0.5rem 0 1.5rem;
    }

    > a {
      display: none;
    }
  }

  ${IS_FRAME_TABLET} {
    margin-bottom: 1.875rem;
    padding: 0 1rem 2rem;

    > h2 {
      color: #364159;
      margin: 1rem 0 1.5rem;
      font-size: 1.25 rem;
    }

    > h3 {
      letter-spacing: 0.25px;
      font-size: 1.25rem;
      margin: 1rem 0 1.5rem;
      font-size: 1.25rem;
    }

    > p {
      line-height: normal;
      line-height: 1.57;
      letter-spacing: 0.25px;
      font-size: 0.875rem;
      margin: 0.5rem 0 1.5rem;
    }

    > a {
      display: none;
    }
  }
`

export const ImgSection = styled.div`
  color: ${darkBlueGrey};
  display: block;
  margin: auto;

  > img {
    width: auto;
    height: 17rem;
    display: block;
    margin: auto;
  }

  > h3 {
    text-align: center;
    font-size: 1.25rem;
  }

  > div {
    line-height: 1.67;
    letter-spacing: 0.1px;
    font-size: 0.875rem;
    text-align: center;
  }

  ${IS_FRAME_MOBILE} {
    > img {
      width: auto;
      height: 10rem;
      display: block;
      margin: 1.5rem auto 0;
    }
  }
`

export const Dot = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: ${({ active }) => active ? cerulean : '#dee2ee'};
  margin: 0.25rem 0;
  
  &:first-child{
    cursor: pointer;
  }

  ${IS_FRAME_MOBILE} {
    display: none;
  }

  ${IS_FRAME_TABLET} {
    display: none;
  }
`
