import { Button } from 'components'
import { cerulean, darkBlueGrey, lightGold, mykonosBlue } from 'components/baseColor'
import styled from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Wrapper = styled.div`
  span {
    font-size: 0.875rem;
    letter-spacing: 0.25px;
  }

   div {
    font-size: 0.75rem;
  }

  ${IS_FRAME_MOBILE} {
    display: block;
  }
`

export const SubmitButton = styled(Button)`
  background-color: ${lightGold};
  border-color: ${lightGold};
  width: 100%;
  color: ${darkBlueGrey};
  font-weight: bold;
  letter-spacing: 0.29px;
  padding: 0.25rem 0;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not([disabled]):hover {
    color: #fff;
    background-color: ${mykonosBlue};
    border: solid 1px #fff;

    svg {
      > g > path {
        fill: #fff;
      }
    }
  }

  svg {
    > g > path {
      fill: ${darkBlueGrey};
    }
  }

  ${IS_FRAME_TABLET} {
    height: 30px;
    margin-top: 2rem;
  }
` 

export const AccountRendering = styled.div`
  font-family: 'Open Sans',system-ui,-apple-system;
  margin-top: 1.25rem;
  text-align: center;
  line-height: 1.62;

  > a {
    text-decoration: none;
    color: ${cerulean};
  }

  span: nth-child(2) {
      color: ${darkBlueGrey};
      font-weight: bold;
      margin-left: 10px;
      cursor: pointer;
  }

  ${IS_FRAME_TABLET} {
    margin-bottom: 1.5rem;
  }
`
