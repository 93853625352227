import React, { useEffect, useRef, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useForm, Controller } from 'react-hook-form'
import {  navigate } from 'gatsby'
import { useIntl } from 'react-intl'
import { 
  CheckBox, 
  InputPhone, 
  InputText 
} from 'components'
import {
  Wrapper,
  SubmitButton,
  CheckUpdate,
  TextUpdate,
  PhoneNumberWrapper
} from './styles'
import Recaptcha from 'react-google-invisible-recaptcha'
import { RegisterFormSchema } from './register-form-schema'
import API, { AffiliateRegisterURL } from 'commons/API'
import { isBrowser } from 'utils'
import { yupResolver } from '@hookform/resolvers/yup'

const RegisterFormStep2 = ({ data, setData }) => {
  const intl = useIntl()
  const refButton = useRef()
  const { addToast } = useToasts()
  const [countryCode, setCountryCode] = useState(data.countryCode)

  const onResolved = () => {
    console.log('Response recaptcha v3' + refButton.current.getResponse())
  }

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    control,
    getValues
  } = useForm({
    resolver      : yupResolver(RegisterFormSchema),
    mode          : 'onTouched',
    defaultValues : {
      company_name      : '',
      company_address   : '',
      country_dial_code : '62',
      phone_number      : '',
      country_iso2      : 'id'
    }
  })


  // initialize value
  useEffect(() => {
    const keys = Object.keys(data)
    if (keys.length) {
      keys.map((key) => {
        const value = data[key]
        if(value) setValue(key, value)
        return ''
      })

      setValue('country_iso2', data.countryCode)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue])  

  const handleSubmitForm = async (values, e) => {
    const HOST = isBrowser ? window.location.host : ''
    const PROTOCOL = isBrowser ? window.location.protocol : ''

    values.country = data.country
    values.activation_link = `${PROTOCOL}//${HOST}/affiliate/set-password/`

    const body = { ...values, ...data }

    await API.post(AffiliateRegisterURL.PostRegister, body)
      .then(() => navigate('/affiliate/registersuccess', { state: { permission: true } }))
      .catch((error) => {
        const errorMsg =
          error?.response?.data?.message ||
          `${error.message}. Please Check Your Internet Connection`
        addToast(errorMsg, { appearance: 'warning' })
      })
  }

  return (
    <form 
      onSubmit={ handleSubmit(handleSubmitForm) }
      autoComplete='off'
    >
      <Wrapper>
        <Controller
          render={ ({ field }) => (
            <InputText
              title={ intl.formatMessage({ id: '39db6928b' }) }
              placeholder={ intl.formatMessage({ id: '6379dd409' }) }
              error={ errors?.company_name?.message ? intl.formatMessage({ id: errors.company_name.message }) : '' }
              ref={ field.ref }
              { ...field }
            />
          ) }
          control={ control }
          name='company_name'
        />

        <Controller
          render={ ({ field }) => (
            <InputText
              title={ intl.formatMessage({ id: 'db4cf1977' }) }
              placeholder={ intl.formatMessage({ id: '4a2dd51c5' }) }
              error={ errors?.company_address?.message ? intl.formatMessage({ id: errors.company_address.message }) : '' }
              ref={ field.ref }
              { ...field }
            />
          ) }
          control={ control }
          name='company_address'
        />

        <PhoneNumberWrapper>
          <Controller
            render={ ({ field }) => (
              <InputPhone
                title={ intl.formatMessage({ id: '7c93abef9' })  }
                defaultCountry={ countryCode || '' }
                value={ getValues().phone_number }
                error={ errors?.phone_number?.message ? intl.formatMessage({ id: errors.phone_number.message }): '' }
                { ...field }
                placeholder={ intl.formatMessage({ id: '23867fe93' })  }
                onChange={ (isValid, phone, country) => {
                  setValue('country_dial_code', country.dialCode)
                  setValue('phone_number', phone.replace(/\D/, '').replace(/^0+/, ''), { shouldValidate: true  })
                }  }
                handleSelectFlag={ (num, country) => {
                  setValue('country_dial_code', country.dialCode)
                  setValue('country_iso2', country.iso2)
                  setCountryCode(country.iso2)
                  setData((prevData) => ({
                    ...prevData,
                    countryCode: country.iso2
                  }))
                } }
                ref={ field.ref }
              />
            ) }
            control={ control }
            name='phone_number'
          />
        </PhoneNumberWrapper>

        <div>
          <CheckUpdate>
            <CheckBox handleClick={ () => {} } />
            <TextUpdate>
              <span>{ intl.formatMessage({ id: '54dad1b02' }) }</span>
              <span>{ intl.formatMessage({ id: '08a86cd00' }) }</span>
            </TextUpdate>
          </CheckUpdate>

          <SubmitButton type='submit' disabled={ isSubmitting }>
            { intl.formatMessage({ id: '64fb78016' }) }
          </SubmitButton>


          <Recaptcha
            sitekey='6Ld-yKcZAAAAABPLzurtwbwZzoVUCriWDkbLRPtQ'
            onResolved={ onResolved }
            ref={ refButton }
          />
        </div>
      </Wrapper>
    </form>
  )
}

export default RegisterFormStep2
