import React, { useState } from 'react'
import { ArrowLeft, LogoQlue } from '@qlue/icon-kit'
import { RegisterFormStep1, RegisterFormStep2 } from './components'
import {
  Wrapper,
  Card, 
  HeaderWrapper,
  Back,
  Grid,
  Dot,
  ImgSection
} from './styles'
import { Link } from 'gatsby'
import ImgStep1 from 'media/images/affiliate-dashboard/register/icon-step-1.svg'
import ImgStep2 from 'media/images/affiliate-dashboard/register/icon-step-2.svg'
import Footer from 'components/Footer'
import { GlobalStyles } from 'components/Layout/styles'
import { DesktopOnly, MobileOnly } from 'components'

const RegisterView = () => {

  const [data, setData] = useState({})
  const [step, setStep] = useState(1)

  const Step1 = () => (
    <>
      <Card>
        <h2>
          Create Affiliate Account
        </h2>
        <RegisterFormStep1 
          data={ data }
          setData={ setData }
          setStep={ setStep }
        />
      </Card>
      <ImgSection>
        <img src={ ImgStep1 } />
        <h3>
          Become Our Affiliates
        </h3>
        <div>
          We aim to accelerate positive changes worldwide by leveraging an AI-based
          comprehensive surveillance system. Join us as Qlue Affiliates
        </div>
      </ImgSection>
    </>
  )

  const Step2 = () => (
    <>
      <Card>
        <h3>
          Nice to meet you!
        </h3>
        <RegisterFormStep2 
          data={ data }
          setData={ setData }
        />
      </Card>
      <ImgSection>
        <img src={ ImgStep2 } />
        <h3>
          Earn The Benefits
        </h3>
        <div>
          Enjoy up to 20% commission fee on-the-go, no administration hassle to earn commission. 
        </div>
      </ImgSection>
    </>
  )

  return(
    <>
      <GlobalStyles />
      <main>
        <Wrapper>
          <HeaderWrapper>
            <MobileOnly>
              {
                step === 2 && (
                  <Back onClick={ () => setStep(1) }>
                    <ArrowLeft />
                    <div>
                Back
                    </div>
                  </Back>
                )
              }
            </MobileOnly>
          
            <Link to='/'>
              <LogoQlue />
            </Link>

            <div>
              Have an account? <Link to='/affiliate-dashboard/login'>LOGIN HERE</Link>
            </div>
          </HeaderWrapper>

          <DesktopOnly>
            {
              step === 2 && (
                <Back onClick={ () => setStep(1) }>
                  <ArrowLeft />
                  <div>
                Back
                  </div>
                </Back>
              )
            }
          </DesktopOnly>

          <Grid>
       
            {
              step === 1 ? <Step1 /> : <Step2 />
            }

            <div style={{ margin: 'auto' }}>
              <Dot 
                active={ step === 1 ? 'active' : '' }
                onClick={ () => setStep(1) }
              />
              { /* disable onclick next */ }
              <Dot active={ step === 2 ? 'active' : '' }/>
            </div>
          </Grid>
        </Wrapper>
      </main>
      <Footer />
    </>
  )
}

export default RegisterView
