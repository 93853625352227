import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Link } from 'gatsby'
import { useIntl } from 'react-intl'
import { ArrowRight } from '@qlue/icon-kit'
import { 
  InputCountry, 
  InputText 
} from 'components'
import {
  Wrapper,
  SubmitButton,
  AccountRendering
} from './styles'
import { RegisterFormSchema } from './register-form-schema'
import { AffiliateRegisterURL } from 'commons/API'
import AffiliateAPI from 'commons/API/affiliate'

const RegisterFormStep1 = ({ 
  data,
  setData,
  setStep 
}) => {
  const intl = useIntl()

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    setError,
    control,
    getValues
  } = useForm({
    resolver      : yupResolver(RegisterFormSchema),
    mode          : 'onTouched',
    defaultValues : {
      name             : '',
      email            : '',
      selected_country : { 
        countryName : 'Indonesia',
        countryCode : 'id'
      }
    }
  })


  const handleSubmitForm = async (values, e) => {
    // validate email already registered / mailinator
    await AffiliateAPI.post(AffiliateRegisterURL.PostValidateRegister, {
      name    : values.name,
      email   : values.email,
      country : values.selected_country.countryName
    })
      .then(() => {
        setData((prevData) => ({
          ...prevData,
          name        : values.name,
          email       : values.email,
          country     : values.selected_country.countryName,
          countryCode : values.selected_country.countryCode
        }))
        setStep(2)
      })
      .catch((error) => {
        const errorMsg = error.response.data.message
        setError('email', {
          type    : 'manual',
          message : errorMsg
        })
      })
  }

  useEffect(() => {
    const keys = Object.keys(data)
    if (keys.length) {
      keys.map((key) => {
        const value = data[key]
        if(value) setValue(key, value)
        return ''
      })
      setValue('selected_country.countryName', data.country)
      setValue('selected_country.countryCode', data.countryCode)
    }
  }, [data, setValue])  

  return (
    <form 
      onSubmit={ handleSubmit(handleSubmitForm) }
      autoComplete='off'
    >
      <Wrapper>

        <Controller
          render={ ({ field, ref }) => (
            <InputText
              title={ intl.formatMessage({ id: 'f22672f2e' }) }
              placeholder={ intl.formatMessage({ id: '262e041a9' }) }
              error={ errors?.name ? intl.formatMessage({ id: errors.name.message }) : '' }
              ref={ field.ref }
              { ...field }
            />
          ) }
          control={ control }
          name='name'
        />

        <Controller
          render={ ({ field, ref }) => (
            <InputText
              title={ intl.formatMessage({ id: 'a8ebc8742' })  }
              placeholder={ intl.formatMessage({ id: 'd100971b3' })  }
              error={ errors?.email?.message ? intl.formatMessage({ id: errors.email.message }) : '' }
              ref={ field.ref }
              type='email'
              { ...field }
            />
          ) }
          control={ control }
          name='email'
        />

        <Controller
          render={ ({ field }) => (
            <InputCountry
              withSearch
              title={ intl.formatMessage({ id: '857111d26' })  }
              keySearch='countryName'
              svgPosition={{ top: '4px' }}
              borderColor='#bcbfc8'
              py='4px'
              errorText={
                errors?.selected_country?.countryName
                  ? intl.formatMessage({ id: errors.selected_country.countryName.message })
                  : ''
              }
              selectedItem={ getValues().selected_country }
              selectedKey='countryName'
              placeholder={ intl.formatMessage({ id: '50f9931ba' })  }
              ref={ field.ref }
              { ...field }
              onChange={ (country) => {
                setValue('selected_country.countryName', country.countryName, {
                  shouldValidate: true
                })
                setValue('selected_country.countryCode', country.alpha2Code)
              } }
              value={ getValues().selected_country }
            />
          ) }
          control={ control }
          name='selected_country'
        />

        <div>
          <SubmitButton 
            type='submit' 
            disabled={ isSubmitting }
          >
            NEXT <ArrowRight />
          </SubmitButton>

          <AccountRendering>
            By continuing, you&apos;re agreeing to the <Link to='/term-service'> Qlue Terms & Condition </Link> and <Link to='/privacy-policy'> Privacy Policy</Link>
          </AccountRendering>
        </div>
      </Wrapper>
    </form>
  )
}

export default RegisterFormStep1
