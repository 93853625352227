import * as Yup from 'yup'

export const RegisterFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(4, '1b2ca0f35')
    .max(30, '59206c5d2')
    .required('88228be84'),
  email: Yup.string()
    .min(4, '1b2ca0f35')
    .max(50, '74c1bf38b')
    .email('09d3105c2')
    .required('88228be84'),
  selected_country: Yup.object().shape({
    countryName: Yup.string()
      .required('88228be84')
  })
})
