import { Button } from 'components'
import { darkBlueGrey, lightGold, lightGrey, mykonosBlue } from 'components/baseColor'
import styled from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Wrapper = styled.div`
  span {
    font-size: 0.875rem;
    letter-spacing: 0.25px;
  }

   div {
    font-size: 0.75rem;
  }

  ${IS_FRAME_MOBILE} {
    display: block;
  }
`

export const SubmitButton = styled(Button)`
  background-color: ${lightGold};
  border-color: ${lightGold};
  width: 100%;
  color: ${darkBlueGrey};
  font-weight: bold;
  letter-spacing: 0.29px;
  padding: 0.6rem 0;
  margin-top: 1rem;

  &:not([disabled]):hover {
    color: #fff;
    background-color: ${mykonosBlue};
    border: solid 1px #fff;
  }

  ${IS_FRAME_TABLET} {
    margin-bottom: 2rem;
  }
` 

export const CheckUpdate = styled.div`
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 10px;
`

export const TextUpdate = styled.div`
    display: flex;
    flex-direction: column;
    letter-spacing: 0.25px;
    span: nth-child(2) {
        font-size: 0.8rem;
        font-family: 'Open Sans',system-ui,-apple-system;
        letter-spacing: 0.25px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        margin-top: 0.5rem;
        color: ${lightGrey};
    }
    span: nth-child(1) {
        font-size: 0.875rem;
        font-family: 'Open Sans',system-ui,-apple-system;
        font-weight: 600;
        margin-bottom: 2px;
        letter-spacing: 0.25px;
        line-height: normal;
    }
    
    ${IS_FRAME_MOBILE} {
      span: nth-child(1) {
        font-size: 0.875rem;
      }
      span: nth-child(2) {
        font-size: 0.75rem;
        margin-top: 0.25rem;
      }
    }
`

export const PhoneNumberWrapper = styled.div`
  margin-top: 0.5rem;
  > div {
    > span {
      margin-top: 0;
    }
  }
`

